<template>
  <div
    class="event-item-compact"
    data-t="event-item"
    :data-is-main="isMainEvent"
    :data-event-id="event.id"
  >
    <div class="top">
      <CompetitorNames
        :event="event"
        class="names"
        compact
        :is-mobile="props.isMobile"
      />
      <ScoreBoardMini
        v-if="isLive"
        class="event-item-scores"
        :event="event"
        :compact="isCompactScoreboard"
        only-scores
      />
      <div v-if="!hideMarkets" class="event-markets">
        <EventMarkets
          v-if="marketConfig"
          :markets-filter="marketConfig"
          :markets="mainMarket"
          :button-props="{ size: 'l', type: isMainEvent ? 'ghost' : 'gray' }"
        />
        <VariantsLink
          v-else
          :count="event.activeMarketsCount"
          :link="eventUrl"
          :type="isMainEvent ? 'ghost' : 'gray'"
        />
      </div>
    </div>
    <div class="bottom">
      <EventStatusBar :event="event" :compact="props.isMobile" />
      <div class="bottom-right">
        <span v-if="marketName" class="active-market-name">
          {{ marketName }}
        </span>
        <EventMarketsCount
          v-if="mainMarketConfig"
          :count="event.activeMarketsCount"
          :link="event.url"
          :is-visible-separator="Boolean(marketName)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TSportEvent } from 'markets-store'
import type { FilterConfig } from 'markets-store/marketsbook/types'
import { SPORT_EVENT_STATUSES_ENUM, SPORT_IDS } from 'markets-store/constants'
import CompetitorNames from '../CompetitorNames/CompetitorNames.vue'
import EventStatusBar from '../EventStatusBar/EventStatusBar.vue'
import EventMarketsCount from '../EventMarketsCount/EventMarketsCount.vue'
import EventMarkets from '../EventMarkets/EventMarkets.vue'
import ScoreBoardMini from '../ScoreBoardMini/ScoreBoardMini.vue'
import { useMainMarket } from '../../composables'
import VariantsLink from '../VariantsLink/VariantsLink.vue'
import { getEventBackground } from '../../helpers/getEventBackground'

const props = withDefaults(
  defineProps<{
    event: TSportEvent
    isMainEvent?: boolean
    filterConfig?: FilterConfig
    isMobile?: boolean
    hideMarkets?: boolean
  }>(),
  {
    isMainEvent: false,
    isMobile: false,
    hideMarkets: false,
  },
)

const { event, filterConfig } = toRefs(props)

const { mainMarketConfig, marketName, mainMarket } = useMainMarket(
  event,
  filterConfig,
)

const isLive = computed(
  () => event.value.status === SPORT_EVENT_STATUSES_ENUM.live,
)
const isCompactScoreboard = computed(
  () => props.isMobile || ![SPORT_IDS.TENNIS].includes(event.value.sportId),
)
const eventBackground = computed(() =>
  props.isMainEvent
    ? `var(${getEventBackground(props.event.sportId).header})`
    : 'var(--sport-group-background, var(--background-primary))',
)
const marketConfig = computed(() => {
  if (props.filterConfig) return props.filterConfig

  return mainMarketConfig.value
})

const eventUrl = computed(() =>
  props.isMobile ? event.value.mobileUrl : event.value.url,
)
</script>

<style scoped>
.names {
  flex-grow: 1;
  min-width: 0;
}

.top {
  display: flex;
  gap: var(--spacing-050);
  padding: var(--spacing-100) var(--spacing-100) 0 var(--spacing-150);
}

.event-item-compact {
  display: flex;
  flex-direction: column;
  background-color: v-bind(eventBackground);
  border-radius: var(--border-radius-150);
}

.bottom {
  display: flex;
  gap: var(--spacing-150);
  align-items: flex-end;
  justify-content: space-between;

  padding: var(--spacing-125) var(--spacing-100) var(--spacing-150)
    var(--spacing-150);
}

.bottom-right {
  display: flex;
}

.active-market-name {
  font: var(--desktop-text-xxs-medium);
  color: var(--text-tertiary);
}

.event-markets {
  width: 164px;
  min-width: 164px;
}

.separator {
  width: 1px;
  height: 8px;
  margin: 0 var(--spacing-075);

  background-color: var(--border-secondary);
  border: none;
}
</style>
